var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-info-dokter-1",attrs:{"id":"info-dokter"}},[_c('div',{staticClass:"text-center"},[_c('label',{staticClass:"position-relative"},[_c('img',{staticClass:"rotate-chev-1 zindex-chev",attrs:{"src":require("@/assets/img/chevron-float.svg"),"id":"chevron-up"},on:{"click":_vm.upInfo}}),_c('img',{staticClass:"position-image-chevron",attrs:{"src":require("@/assets/img/ellipse.svg")}})])]),_c('div',{staticClass:"row caredokter-info-charge align-items-end",class:_vm.detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||  _vm.detailTransaksi.paymentTransactionStatus == 'EXPIRED' ? 'text-cancel': ''},[_c('div',{staticClass:"col-6"},[_c('span',{staticClass:"total-pembayaran"},[_vm._v(_vm._s(_vm.staticText.totalPembayaran))])]),_c('div',{staticClass:"col-6 text-right"},[(_vm.isOpenFloat  && !(
      _vm.detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
      _vm.detailTransaksi.paymentTransactionStatus == 'PAID' ))?_c('span',{staticClass:"total-pembayaran"},[_vm._v(_vm._s(_vm.staticText.batasTransaksi))]):_vm._e()])]),_c('div',{staticClass:"row caredokter-info-charge mb-2",class:_vm.detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||  _vm.detailTransaksi.paymentTransactionStatus == 'EXPIRED' ? 'text-cancel': ''},[_c('div',{staticClass:"col-6 m-0"},[_c('p',{staticClass:"charge-pay font-nunito m-0"},[_vm._v(" Rp "+_vm._s(_vm._f("setCurrency")(_vm.detailTransaksi.grossAmount))+" ")]),_c('p',{staticClass:"text-bayar-footer m-0 span-ellipsis"},[_vm._v(" ID : "+_vm._s(_vm.detailTransaksi.transactionOrderId)+" ")])]),_c('div',{staticClass:"col-6 text-right m-0"},[(
        !_vm.isOpenFloat && (
      _vm.detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
      _vm.detailTransaksi.paymentTransactionStatus == 'PAID' ||
      _vm.detailTransaksi.paymentTransactionStatus == 'EXPIRED')
    )?_c('button',{staticClass:"btn caredokter-button slide-to-left-2",on:{"click":_vm.goProcess}},[_vm._v(" "+_vm._s(_vm.staticText.selesai)+" ")]):(
          !_vm.isOpenFloat &&
          _vm.showIntroduction &&
          (_vm.detailTransaksi.paymentTransactionStatus == 'CREATED' ||
            _vm.isChangeMethodPayment)
        )?_c('button',{staticClass:"btn caredokter-button slide-to-left-2",attrs:{"disabled":_vm.isLoadingCharge},on:{"click":_vm.chargePayment}},[(_vm.isLoadingCharge)?[_vm._m(0)]:[_vm._v(" "+_vm._s(_vm.staticText.bayar)+" ")]],2):_vm._e(),(_vm.isOpenFloat && !(
      _vm.detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
      _vm.detailTransaksi.paymentTransactionStatus == 'PAID' ))?[_c('div',{staticClass:"caredokter-date-info-mobile slide-to-left-2"},[_c('p',{staticClass:"date m-0"},[_vm._v(_vm._s(_vm._f("showOnlyDate")(_vm.detailTransaksi)))]),_c('p',{staticClass:"date m-0"},[_vm._v(_vm._s(_vm._f("showOnlyTime")(_vm.detailTransaksi)))]),_c('p',{staticClass:"timer-countdown m-0"},[_vm._v(" "+_vm._s(_vm._f("timerCountdown")(_vm.now,_vm.detailTransaksi))+" ")])])]:_vm._e()],2)]),_c('detail-purchase-mobile')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"m-0"},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")])
}]

export { render, staticRenderFns }