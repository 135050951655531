var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[(
      _vm.detailTransaksi.paymentTransactionStatus == 'UNDERPAYMENT' &&
      _vm.isBtn &&
      !_vm.isChangeMethodPayment &&
      !_vm.isLoadingCharge
    )?[_c('div',[_c('button',{staticClass:"btn btn-outline-caredokter",class:!_vm.isRunMobile ? 'float-right ': 'btn-block',on:{"click":_vm.changeMethod}},[_vm._v(" "+_vm._s(_vm.staticText.ubahMetode)+" ")]),_c('a',{staticClass:"mt-1 btn btn-link caredokter-link-a",on:{"click":_vm.cancelPayment}},[_vm._v(_vm._s(_vm.staticText.batalkan))])])]:(
      _vm.detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
      _vm.detailTransaksi.paymentTransactionStatus == 'PAID' ||
      _vm.detailTransaksi.paymentTransactionStatus == 'EXPIRED'
    )?[_c('button',{staticClass:"float-right caredokter-button",on:{"click":_vm.finish}},[_vm._v(_vm._s(_vm.staticText.selesai))])]:[_c('button',{staticClass:"caredokter-button",class:[!_vm.isRunMobile ? 'float-right ': 'btn-block', _vm.isLoadingCharge ? 'btn-disabled' : ''],attrs:{"disabled":_vm.isLoadingCharge},on:{"click":_vm.btnAction}},[(_vm.isLoadingCharge)?[_vm._m(0)]:[_vm._v(" "+_vm._s(_vm.lakukanPembayaran)+" ")]],2)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"m-0"},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")])
}]

export { render, staticRenderFns }