<template>
    <div class="customer-detail-purchase-mobile border-caredokter-mobile">
        <label >{{staticText.pesanan}}</label>
         <template v-for="(item, index) in list" >
            <order :item="item" :key="index"/>
        </template>
    </div>
    
</template>
<script>
import Order from '../leftComponent/Order.vue'
import StaticText from "@/helpers/StaticText";

export default {
    data() {
        return {
            staticText: new StaticText()
        }
    },
    components: {
        Order
    },
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
}
</script>