<template>
  <div class="bg-info-dokter-1" id="info-dokter">
    <div class="text-center ">
      <label class="position-relative">
      <img
        src="@/assets/img/chevron-float.svg"
        id="chevron-up"
        class=" rotate-chev-1 zindex-chev"
        @click="upInfo"
      />
       <img
        src="@/assets/img/ellipse.svg"
        class="position-image-chevron"
      />
      </label>
      <!--  src="@/assets/img/chevron-float.svg" -->
    </div>
    <div class="row caredokter-info-charge align-items-end" :class="detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||  detailTransaksi.paymentTransactionStatus == 'EXPIRED' ? 'text-cancel': ''">
      <div class="col-6">
        <span class="total-pembayaran">{{staticText.totalPembayaran}}</span>
      </div>
      <div class="col-6 text-right">
        <span class="total-pembayaran" v-if="isOpenFloat  && !(
        detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
        detailTransaksi.paymentTransactionStatus == 'PAID' )">{{staticText.batasTransaksi}}</span>
      </div>
    </div>
    <div class="row caredokter-info-charge mb-2" :class="detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||  detailTransaksi.paymentTransactionStatus == 'EXPIRED' ? 'text-cancel': ''">
      <div class="col-6 m-0">
        <p class="charge-pay font-nunito m-0">
          Rp {{ detailTransaksi.grossAmount | setCurrency }}
        </p>
        <p class="text-bayar-footer m-0 span-ellipsis">
          ID : {{ detailTransaksi.transactionOrderId }}
        </p>
      </div>
      <div class="col-6 text-right m-0">
          <button  v-if="
          !isOpenFloat && (
        detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
        detailTransaksi.paymentTransactionStatus == 'PAID' ||
        detailTransaksi.paymentTransactionStatus == 'EXPIRED')
      " class="btn caredokter-button slide-to-left-2" @click="goProcess">
              {{staticText.selesai}}
          </button>
        <button
          :disabled="isLoadingCharge"
          class="btn caredokter-button slide-to-left-2"
          @click="chargePayment"
          v-else-if="
            !isOpenFloat &&
            showIntroduction &&
            (detailTransaksi.paymentTransactionStatus == 'CREATED' ||
              isChangeMethodPayment)
          "
        >
          <template v-if="isLoadingCharge">
            <label class="m-0">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </label>
          </template>
          <template v-else> {{staticText.bayar}} </template>
        </button>
        <template v-if="isOpenFloat && !(
        detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
        detailTransaksi.paymentTransactionStatus == 'PAID' )">
          <div class="caredokter-date-info-mobile slide-to-left-2">
            <p class="date m-0">{{ detailTransaksi | showOnlyDate }}</p>
            <p class="date m-0">{{ detailTransaksi | showOnlyTime }}</p>
            <p class="timer-countdown m-0">
              {{ now | timerCountdown(detailTransaksi) }}
            </p>
          </div>
        </template>
      </div>
    </div>
    <detail-purchase-mobile />
   
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DetailPurchaseMobile from "./DetailPuchaseMobile.vue";
import StaticText from "@/helpers/StaticText";

import moment from "moment";
export default {
  components: {
    DetailPurchaseMobile,
  },
  data() {
    return {
      now: new Date(),
      staticText: new StaticText()
    };
  },
  computed: {
    ...mapState({
      detailTransaksi: (state) => state.detailTransaksi,
      showIntroduction: (state) => state.showIntroduction,
      isChangeMethodPayment: (state) => state.isChangeMethodPayment,
      isOpenFloat: (state) => state.isOpenFloat,
      isLoadingCharge: (state) => state.isLoadingCharge,
    }),
  },
  mounted() {
    setInterval(() => {
      this.now = new Date();
    }, 1000);
    setTimeout(() => {
      const infoDokter = document.getElementById("info-dokter");
      const detailInfo = document.getElementById("detail-info");
      const heightMobileInfo = detailInfo.clientHeight;
      // console.log(heightMobileInfo)
      infoDokter.style.bottom = `-${heightMobileInfo}px`;
    }, 1000);
  },
  methods: {
    ...mapActions({
      upInfo: "CHEVRON_ACTION",
      chargePayment: "SET_BTN_TO",
    }),
    goProcess() {
        window.location.href = process.env.VUE_APP_AFTER_PAYMENT
    }
  },
  filters: {
    setCurrency(item) {
      // eslint-disable-next-line no-undef
      return helpers.changeCurrency(item);
    },
    showOnlyDate(item) {
      // if (!item.payment) return "";
      if(!item.expiredAt)return ""
      let timeExpPayment
      if(item.payment) {
        timeExpPayment =
        item.payment && item.payment.timeExpired
          ? moment(item.payment.timeExpired).format("x")
          : 0;
      } else {
        timeExpPayment = 0
      }
      const timeExpLink = item.expiredAt
        ? moment(item.expiredAt).format("x")
        : 0;
       
      if (timeExpLink > timeExpPayment && timeExpPayment > 0 )
        return moment(timeExpPayment, "x").format("DD/MM/YYYY");
      return moment(timeExpLink, "x").format("DD/MM/YYYY");
    },
    showOnlyTime(item) {
      if (!item.expiredAt) return "";
      const timeExpLink = item.expiredAt
        ? moment(item.expiredAt).format("x")
        : 0;
      const timeExpPayment =
        item.payment && item.payment.timeExpired
          ? moment(item.payment.timeExpired).format("x")
          : 0;
      if (timeExpLink > timeExpPayment && timeExpPayment > 0)
        return moment(timeExpPayment, "x").format("HH:mm");
      return moment(timeExpLink, "x").format("HH:mm");
    },
    timerCountdown(now, item) {
      if(!item.expiredAt)return ""  
      const timeExpLink = item.expiredAt
        ? moment(item.expiredAt).format("x")
        : 0;
      const timeExpPayment =
        item.payment && item.payment.timeExpired
          ? moment(item.payment.timeExpired).format("x")
          : 0;
      const timeX = moment(now).format("x");
      let countDown = "00:00";
      if (timeExpLink > timeExpPayment && timeExpPayment > 0) {
        countDown = timeExpPayment;
      } else {
        countDown = timeExpLink;
      }
      if (countDown > timeX) {
        const diffTime = Math.floor((countDown - timeX) / 1000);
        if(diffTime > 86400) return ''
        // eslint-disable-next-line no-undef
        return helpers.setTimer(diffTime);
      }
      return "00: 00";
    },
  },
};
</script>

<style></style>
