<template>
  <div class="customer-detail-purchase">
    <div class="grid-purchase sticky-purchase text-font-1">
      <label class="purchase-title">{{ pesanan }}</label>
      <label class="text-right purchase-title">{{ price }}</label>
    </div>
    <hr class="m-0" />
    <template v-for="(item, index) in list">
      <order :item="item" :key="index" />
    </template>
    <div v-if="!id">
      <template v-for="i in 3">
        <div :key="i" :class="[i < 3 ? 'hr-dashed' : '', 'pb-1']">
          <div class="grid-purchase">
            <rectangle-skleton
              :ref-skleton="'snap-name'"
              class="m-0"
              :x-width="'150px'"
              :x-height="'21px'"
            />
            <div class="flex-end">
              <rectangle-skleton
                :ref-skleton="'snap-name'"
                :x-width="'80px'"
                :x-height="'21px'"
              />
            </div>
          </div>
          <div class="grid-purchase">
            <rectangle-skleton
              :ref-skleton="'snap-name'"
              class="m-0"
              :x-width="'150px'"
              :x-height="'21px'"
            />
            <div class="flex-end">
              <rectangle-skleton
                :ref-skleton="'snap-name'"
                :x-width="'80px'"
                :x-height="'21px'"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Order from "./Order.vue";
import RectangleSkleton from "../skleton/RectangleSkleton.vue";
import StaticText from "@/helpers/StaticText";
export default {
  data() {
    return {
      pesanan: new StaticText().pesanan,
      price: new StaticText().price,
    };
  },
  components: {
    Order,
    RectangleSkleton,
  },
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
