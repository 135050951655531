<template>
    <div class="bg-white caredokter-info-mobile" id="detail-info">
       <status-order class="border-caredokter-mobile" :detail="{
              paymentTransactionStatus: detailTransaksi.paymentTransactionStatus, 
               createdDate: detailTransaksi.createdDate,
               transactionTime: detailTransaksi.payment && detailTransaksi.payment.transactionStatus === 'settlement' ? detailTransaksi.payment.transactionTime : ''
               }" />
        <list-order-mobile :list="detailTransaksi.itemDetail"/>
        <detail-info-customer-mobile class="mb-72" :data-customer="detailTransaksi.customerDetail" :transaction-order="detailTransaksi.transactionOrderId "/>
        <div class="btn-margin"  v-if="btnNameFloat && isOpenFloat">
            <button-payment class="btn-all slide-to-left-2 " />
        </div>
    </div>
</template>
<script>
import ListOrderMobile from './ListOrderMobile.vue'
import DetailInfoCustomerMobile from './DetailInfoCustomer.vue'
import StatusOrder from '../leftComponent/StatusOrder.vue'
import {mapState} from 'vuex'
import ButtonPayment from '../StatusPayment/ButtonPayment.vue'
export default {
    components: {
        StatusOrder,
        ListOrderMobile,
        DetailInfoCustomerMobile,
        ButtonPayment
    },
     computed: {
        ...mapState({
            detailTransaksi: (state) => state.detailTransaksi,
            btnNameFloat: (state) => state.btnNameFloat,
            isOpenFloat: (state) => state.isOpenFloat,
        })
    },
}
</script>