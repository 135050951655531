<template>
     <div class="caredokter-padding-detail" :class="detailTransaksi.paymentTransactionStatus">
            <label class="caredokter-snap-title text-font-title">{{detailTransaksi.snapName}}</label>
            <rectangle-skleton v-if="!detailTransaksi.snapName"  :ref-skleton="'snap-name'"
            class="m-0 ml-2 mb-2"
                :x-width="'150px'"
                :x-height="'35px'" />
            <customer-detail 
              :transaction-order="detailTransaksi.transactionOrderId "
              :detail="detailTransaksi.customerDetail" 
              :is-loading="detailTransaksi.id ? false: true"
            />
            <status-order :detail="{
              paymentTransactionStatus: detailTransaksi.paymentTransactionStatus, 
               createdDate: detailTransaksi.createdDate,
               transactionTime: detailTransaksi.payment && detailTransaksi.payment.transactionStatus === 'settlement' ? detailTransaksi.payment.transactionTime : ''
               }" />
            <list-order :list="detailTransaksi.itemDetail" :id="detailTransaksi.transactionOrderId"/>
          </div>
</template>
<script>
import {mapState} from 'vuex'
import CustomerDetail from './CustomerDetail.vue'
import StatusOrder from './StatusOrder.vue'
import ListOrder from './ListOrder.vue'
import RectangleSkleton from "../skleton/RectangleSkleton.vue";
export default {
   components: {
     CustomerDetail,
     StatusOrder,
     ListOrder,
     RectangleSkleton
   },
    computed: {
        ...mapState({
            detailTransaksi: (state) => state.detailTransaksi
        })
    },
}
</script>