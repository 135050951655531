<template>
  <div class="set-center">
    <div class="d-relative">
      <img class="img-fluid" src="../assets/img/404.png" alt="">
      <div class="text-center ">
        <p class="text-font-1">Error {{errorMessage && errorMessage.status ? errorMessage.status: ''}}</p>
        <span class="error-oops">Oops! </span>
        <p class="text-font-1">{{errorMessage && errorMessage.status ? errorMessage.title: ''}}</p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      errorMessage: state => state.errorHandle
    })
  },
};
</script>