<template>
   <div class="customer-detail-purchase-mobile border-caredokter-mobile">
        <label >{{staticText.detailPembeli}}</label>
         <p v-if="DataCustomer.first_name || DataCustomer.last_name" class="caredokter-name-mobile">{{DataCustomer.first_name}} {{DataCustomer.last_name}}</p>
          <p v-else class="caredokter-name-mobile">{{staticText.guest}}</p>
         <p class="text-caredokter-info-mobile font-nunito">{{DataCustomer.phone}}</p>
         <p class="text-caredokter-info-mobile">{{DataCustomer.email}}</p>
    </div>
</template>

<script>
import StaticText from "@/helpers/StaticText";

export default {
     data() {
          return {
              staticText: new StaticText()
          }
     },
   props: {
        DataCustomer: {
             type: [Object, String],
             default: () => {
                  return {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: ''
                  }
             }
        },
        transactionOrder: {
            default: '',
            type: String
        },
   }
}
</script>

<style>

</style>