<template>
  <div class="footer-select-payment">
          <div class="row">
            <div class="col-5">
              <label class="text-font-1 m-0 d-block">{{total}} </label>
              <label class="caredokter-amount font-nunito text-font-title">Rp {{detailTransaksi.grossAmount | setCurrency}}</label>
            </div>
            <button-payment v-if="btnNameFloat" class="col-7" />
          </div>
        </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex'
import StaticText from '@/helpers/StaticText'
import ButtonPayment from '../StatusPayment/ButtonPayment.vue'
export default {
  components:{
    ButtonPayment
  },
  data(){
    return {
     total:  new StaticText().totalPembayaran,
     lakukanPembayaran: new StaticText().lakukanPembayaran
    }
  },
  computed: {
    ...mapState({
       detailTransaksi: (state) => state.detailTransaksi,
        isBtn: (state) => state.isButtonShowDetail,
        isChangeMethodPayment: state => state.isChangeMethodPayment,
        btnNameFloat: (state) => state.btnNameFloat,
    })
  },
  methods: {
     ...mapMutations({
      changeDetail: "SET_IS_SHOW_DEFAULT",

    }),
  },
  filters: {
     setCurrency(item) {
          // eslint-disable-next-line no-undef
          return helpers.changeCurrency(item)
      }
  }
}
</script>

<style>

</style>