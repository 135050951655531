<template>
  <div class="text-center">
    <template
      v-if="
        detailTransaksi.paymentTransactionStatus == 'UNDERPAYMENT' &&
        isBtn &&
        !isChangeMethodPayment &&
        !isLoadingCharge
      "
    >
      <div>
        <button
        class="btn btn-outline-caredokter"
          :class="!isRunMobile ? 'float-right ': 'btn-block'"
          @click="changeMethod"
        >
          {{staticText.ubahMetode}}
        </button>
        <a 
        class="mt-1 btn btn-link caredokter-link-a" @click="cancelPayment"
          >{{staticText.batalkan}}</a
        >
      </div>
    </template>
    <template
      v-else-if="
        detailTransaksi.paymentTransactionStatus == 'CANCELLED' ||
        detailTransaksi.paymentTransactionStatus == 'PAID' ||
        detailTransaksi.paymentTransactionStatus == 'EXPIRED'
      "
    >
      <button class="float-right caredokter-button " @click="finish">{{staticText.selesai}}</button>
    </template>
    <template v-else>
      <button class="caredokter-button" 
        :class="[!isRunMobile ? 'float-right ': 'btn-block', isLoadingCharge ? 'btn-disabled' : '']"
        @click="btnAction" :disabled="isLoadingCharge">
        <template v-if="isLoadingCharge">
            <label class="m-0">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
            </label>
        </template>
        <template v-else>
            {{ lakukanPembayaran }}
        </template>
      </button>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import StaticText from '@/helpers/StaticText'

export default {
    data() {
        return {
            lakukanPembayaran: new StaticText().lakukanPembayaran,
            staticText: new StaticText()
        }
    },
  computed: {
    ...mapState({
        isLoadingCharge: state => state.isLoadingCharge,
        detailTransaksi: (state) => state.detailTransaksi,
       isBtn: (state) => state.isButtonShowDetail,
       isChangeMethodPayment: state => state.isChangeMethodPayment,
       isRunMobile: state => state.isRunMobile
    }),
  },
  methods: {
      ...mapMutations({
          setChangeMethodPayment: 'SET_CHANGE_METHOD_PAYMENT',
       
      }),
       ...mapActions({
        btnAction: 'SET_BTN_TO',
         getDispatch: 'CHECK_HAS_CHILD',
         cancelPayment: 'CANCEL_ORDER_PAYMENT',
        upInfo: 'CHEVRON_ACTION'
       }),
        changeMethod() {
            // changeMethod Payment
            if(this.isRunMobile){
                this.upInfo()
            }
        this.setChangeMethodPayment(true)
        this.getDispatch('')
    },
    finish() {
        // alert(process.env.VUE_APP_AFTER_PAYMENT)
        window.location.href = process.env.VUE_APP_AFTER_PAYMENT
    }
  }
};
</script>

<style></style>
