<template>
    <div class="footer-detail" :class="detailTransaksi.paymentTransactionStatus">
          <div class="row">
            <div class="col-8 m-0 text-info-pembayaran text-font-2">
                {{detailTransaksi.paymentTransactionStatus == 'EXPIRED' ? textEpiredIn : textExpiredAt}}
              </div>
            <div class="col-4 text-right m-0">
              <label class="date font-nunito text-font-2">{{detailTransaksi.expiredAt  | onlyDate}}</label>
              <label class="hours font-nunito text-font-title">{{detailTransaksi.expiredAt | onlyTime}}</label>
            </div>
          </div>
        </div>
</template>
<script>
import {mapState} from 'vuex'
import StaticText from '@/helpers/StaticText'
import moment from 'moment'
moment.locale('id')
export default {
  computed: {
      ...mapState({
            detailTransaksi: (state) => state.detailTransaksi
        })
  },
  data() {
    return {
      textExpiredAt: new StaticText().expiredAt,
      textEpiredIn: new StaticText().expiredIn
    }
  },
  filters: {
    onlyDate(date) {
      if(date)  return moment(date).format('DD-MMM-YYYY')
      return ''
    },
    onlyTime(date) {
      if(date) return moment(date).format('HH:mm')
      return ''
    }
  }
}
</script>