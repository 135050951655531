/* eslint-disable no-undef */
<template>
  <div class="hr-dashed text-font-2">
    <template v-if="item.itemType == 'FEE'">
      <div>
        <div class="grid-purchase mt-0">
          <label class="subtotal">{{ item.name }}</label>
          <div class="description-item">
            <div class="text-right original" v-if="item.originalPrice">
              {{ item.originalPrice | setCurrency }}
            </div>
            <div class="text-right amount-total font-nunito">
              {{ item.price | setCurrency }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="item.itemType != 'DELIVERY'">
      <div class="grid-purchase my-1 description-item">
        <label class="detail-item m-0">{{ item.name }}</label>
        <div class="text-right font-nunito">
          <span class="original pr-2" v-if="item.originalPrice">{{
            item.originalPrice | setCurrency
          }}</span>
          <span>{{ item.price | setCurrency }}</span>
        </div>
      </div>
      <div class="grid-purchase">
        <label class="subtotal">Subtotal (x{{ item.quantity }})</label>
        <label class="text-right amount-total font-nunito">{{
          (item.price * item.quantity) | setCurrency
        }}</label>
      </div>
    </template>
    <template v-else>
      <div>
        <div class="grid-purchase">
          <label class="detail-item">Pengiriman</label>
        </div>
        <div class="grid-purchase">
          <label class="subtotal">{{ item.name }}</label>
          <div class="description-item">
            <div class="text-right original" v-if="item.originalPrice">
              {{ item.originalPrice | setCurrency }}
            </div>
            <div class="text-right amount-total">
              Rp{{ item.price | setCurrency }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  filters: {
    setCurrency(item) {
      if (!item) return 0;
      // eslint-disable-next-line no-undef
      if (item < 0) return "- Rp " + helpers.changeCurrency(item);
      // eslint-disable-next-line no-undef
      return "Rp " + helpers.changeCurrency(item);
    },
  },
};
</script>
