<template>
  <div class="customer-date-pembelian text-font-3">
    <div class="detail ">{{ status }}</div>
    <div class="text-right status " :class="detail.paymentTransactionStatus">
      {{ staticText[detail.paymentTransactionStatus] }}
      <rectangle-skleton
        v-if="!detail.createdDate"
        :ref-skleton="'snap-name'"
        class="float-right"
        :x-width="'60px'"
        :x-height="'15px'"
      />
    </div>
    <div class="detail ">{{ pembelian }}</div>
    <div class="text-right status">
      {{ detail.createdDate | showDate }}
      <rectangle-skleton
        v-if="!detail.createdDate"
        :ref-skleton="'snap-name'"
        class="float-right"
        :x-width="'120px'"
        :x-height="'15px'"
      />
    </div>
    <template v-if="detail.transactionTime">
      <div class="detail">{{ pembayaran }}</div>
      <div class="text-right status">
        {{ detail.transactionTime | showDate }}
        <rectangle-skleton
          v-if="!detail.createdDate"
          :ref-skleton="'snap-name'"
          class="float-right"
          :x-width="'120px'"
          :x-height="'15px'"
        />
      </div>
    </template>
  </div>
</template>
<script>
import RectangleSkleton from "../skleton/RectangleSkleton.vue";
import StaticText from "@/helpers/StaticText";
import moment from "moment";
export default {
  components: {
    RectangleSkleton,
  },
  data() {
    return {
      status: new StaticText().status,
      pembelian: new StaticText().pembelian,
      pembayaran: new StaticText().pembayaran,
       staticText: new StaticText()
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    showDate(item) {
      if (item) return moment(item).format("DD/MM/YYYY, HH:mm");
      return "";
    },
  },
};
</script>
